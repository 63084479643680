import React from 'react';
import PropTypes from 'prop-types';
import Container from 'components/container';
import { yearsFromNow } from 'utils/dates';
import './About.scss';

const About = () => (
	<div className="about">
		<Container>
			<Container>
				<h2 className="title">
					Hey there,<br/>
					My name is Or - CTO, founder, father<br/>
					and startup advisor.
				</h2>
				<p>Over the past {yearsFromNow('2013')} years, I have been working with companies to grow and achieve their potential through the use of innovative technologies and best in class strategies.</p>
				<p>
					<strong>Every founder reaches that challenging point where they need to take their business to the next level.</strong> Whether you are trying to develop a concept to raise capital investment, grow a bootstrapped team into a fully operational company or adapt to your consumer base requirements - I will help you navigate and implement decisions that will grow your business and create a strong IT position within your industry.<br /><br />
					Let‘s have a chat about your project.
				</p>
			</Container>
		</Container>
	</div>
);

About.propTypes = {
	onGetFreeConsultationClick: PropTypes.func.isRequired
};

export default About;

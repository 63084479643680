import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import CVAchievement from './CVAchievement';
import './CVAchievements.scss';

const CVAchievements = ({ className, achievements }) => (
	<div className={classnames(className, 'cv-achievements')}>
		<h2>Achievements</h2>
		<div className="cv-achievements__list">
			{achievements.map(({ title, date, description }, index) =>
				<CVAchievement key={`cv-achievement${index}`} title={title} date={new Date(date)} description={description} />
			)}
		</div>
	</div>
);

CVAchievements.propTypes = {
	className: PropTypes.string,
	achievements: PropTypes.arrayOf(PropTypes.shape({
		title: PropTypes.string.isRequired,
		date: PropTypes.string.isRequired,
		description: PropTypes.string.isRequired
	})).isRequired
};

export default CVAchievements;

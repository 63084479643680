import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import './CVAchievement.scss';

const CVAchievement = ({ title, date, description }) => (
    <div className="cv-achievement">
		<div className="cv-achievement__date">{moment(date).format('YYYY')}</div>
		<div className="cv-achievement__title">{title}</div>
		<div className="cv-achievement__description">{description}</div>
	</div>
);

CVAchievement.propTypes = {
    title: PropTypes.string.isRequired,
    date: PropTypes.instanceOf(Date).isRequired,
    description: PropTypes.string.isRequired
};

export default CVAchievement;
import React, { Component} from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Modal from 'components/modal';
import { RouterWatcher, Home, CV } from 'pages';
import './App.scss';

function getViewportHeight() {
	return window.innerHeight;
}

class App extends Component {
	static propTypes = {
		isModalOpen: PropTypes.bool,
		viewportHeight: PropTypes.number,
		scrollYPosition: PropTypes.number
	}

	static defaultProps = {
		isModalOpen: false,
		viewportHeight: getViewportHeight(),
		scrollYPosition: 0
	}

	lockViewportHeight = false

	state = {
		viewportHeight: getViewportHeight()
	}

	constructor(props) {
		super(props);

		this.setScroll = this.setScroll.bind(this);
		this.handleWindowResize = this.handleWindowResize.bind(this);
		this.handleModalOpen = this.handleModalOpen.bind(this);
		this.handleModalClose = this.handleModalClose.bind(this);
		this.handleModalRender = this.handleModalRender.bind(this);
	}

	componentDidMount() {
		window.addEventListener('resize', this.handleWindowResize);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleWindowResize);
	}

	componentDidUpdate() {
		this.lockViewportHeight = false;
	}

	handleWindowResize() {
		if (!this.lockViewportHeight) {
			this.lockViewportHeight = true;

			this.setState({
				viewportHeight: getViewportHeight()
			});
		}
	}

	setScroll() {
		const documentElement = document.documentElement || document.body
				, {
						appElement,
						props: {
							scrollYPosition
						}
					} = this;		

		documentElement.scrollTop = scrollYPosition;
		appElement && (appElement.scrollTop = scrollYPosition);
	}

	handleModalOpen() {
		this.setScroll();
		window.scrollTo(0, 0);
	}

	handleModalClose() {
		this.setScroll();
	}

	handleModalRender(modalWidth, modalHeight) {
		this.setState({ modalHeight });
	}

	render() {
		const {
						handleModalOpen,
						handleModalClose,
						handleModalRender,
						props: {
							isModalOpen,
							viewportHeight
						},
						state: {
							modalHeight
						}
					} = this
				, appStyle = {
					height: isModalOpen ? `${Math.max(viewportHeight, modalHeight)}px` : 'inherit'
				};

		return (
			<div ref={element => this.appElement = element} className={classnames('covid19', 'app', { 'app--modal-open': isModalOpen })} style={appStyle}>
				<Modal onOpen={handleModalOpen} onClose={handleModalClose} onRender={handleModalRender} />
				<Router>
					<RouterWatcher />
					<Switch>
						<Route path="/cv" component={CV} />
						<Route path="/" component={Home} />
					</Switch>
				</Router>
			</div>
		);
	}
}

export default connect(
	({ app: { scrollYPosition }, modal: { isOpen }}) => ({
		isModalOpen: isOpen,
		viewportHeight: getViewportHeight(),
		scrollYPosition
	})
)(App);

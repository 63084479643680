import React from 'react';
import PropTypes from 'prop-types';
import Button, { LINK_BUTTON_TYPE } from './Button';

const LinkButton = ({ children, onClick, ...props }) => (
	<Button {...props} type={LINK_BUTTON_TYPE} onClick={onClick}>{children}</Button>	
);

LinkButton.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.element,
		PropTypes.string
	]),
	onClick: PropTypes.func
};

export default LinkButton;
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TwitterLink, LinkedinLink } from 'components/buttons';
import { event, outboundLink } from 'utils/analytics';
import './MenuItem.scss';

const TWITTER_TYPE = 'twitter'
		, LINKEDIN_TYPE = 'linkedin';

function renderLabel(label, icon) {
	return (
		<span>
			{icon && <FontAwesomeIcon className="menu__item__icon" icon={icon.split(' ')} />}
			{label}
		</span>
	);
}

const MenuItem = ({ className, type, label, icon, url, link, onClick }) => {
	const finalClassName = classnames('menu__item', className);

	switch (type) {
		case TWITTER_TYPE:
			return (
				<TwitterLink className={finalClassName} withHandle={false} />
			);
		case LINKEDIN_TYPE:
			return (
				<LinkedinLink className={finalClassName} />
			);
		default:
			if (onClick) {
				return (
					<button className={finalClassName} onClick={() => (event('menu item', 'click', label), onClick())}>{renderLabel(label, icon)}</button>
				);
			} else if (link) {
				return (
					<Link className={finalClassName} to={link}>{renderLabel(label, icon)}</Link>
				);
			} else {
				return (
					<a className={finalClassName} href={url} onClick={() => /^[/#]/.test(url) ? event('menu item', 'click', label) : outboundLink(label)}>{renderLabel(label, icon)}</a>
				);
			}
	}

};

MenuItem.propTypes = {
	className: PropTypes.string,
	type: PropTypes.oneOf([ TWITTER_TYPE, LINKEDIN_TYPE ]),
	label: PropTypes.string,
	icon: PropTypes.string,
	link: PropTypes.string,
	url: PropTypes.string,
	onClick: PropTypes.func
};

MenuItem.defaultProps = {
	url: '#'
};

MenuItem.TWITTER_TYPE = TWITTER_TYPE;
MenuItem.LINKEDIN_TYPE = LINKEDIN_TYPE;

export default MenuItem;

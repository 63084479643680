import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { close } from 'actions/modal';
import AbstractForm, { FIELD_TYPES, FIELD_SIZES } from 'components/forms/Form';
import { sendMessage } from 'api';

class Form extends Component {
	static propTypes = {
		onClose: PropTypes.func.isRequired
	}

	state = {
		fields: [{
			type: FIELD_TYPES.INPUT,
			name: 'name',
			placeholder: 'Your name',
			size: FIELD_SIZES.SHORT,
			validation: {
				mandatory: true
			}
		}, {
			type: FIELD_TYPES.INPUT,
			name: 'email',
			placeholder: 'Contact email',
			size: FIELD_SIZES.SHORT,
			validation: {
				mandatory: true,
				email: true
			}
		},  {
			type: FIELD_TYPES.INPUT,
			name: 'subject',
			placeholder: 'Subject',
			validation: {
				mandatory: true
			}
		}, {
			type: FIELD_TYPES.TEXTAREA,
			name: 'message',
			placeholder: 'What would you like to talk about?',
			validation: {
				mandatory: true
			}
		}]
	}

	constructor(props) {
		super(props);

		this.handleInvalid = this.handleInvalid.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleInvalid() {}

	handleSubmit({ name, email, message, subject }) {
		return sendMessage(name, email, message, subject);
	}

	render() {
		const {
						handleInvalid,
						handleSubmit,
						props: {
							onClose
						},
						state: {
							fields
						}
					} = this;

		return (
			<AbstractForm
				fields={fields}
				clearLabel="Back"
				submitLabel="Send message"
				successMessage="Thank you for you interest. I will be in touch shortly."
				onSubmit={handleSubmit}
				onClear={onClose}
				onInvalid={handleInvalid} />
		);
	}
}

export default connect(
	null,
	dispatch => ({
		onClose: () => dispatch(close())	
	})
)(Form);

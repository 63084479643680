import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button, { LinkButton } from 'components/buttons';
import Field, { FIELD_TYPES, FIELD_SIZES } from './Field';
import { event } from 'utils/analytics';
import './Form.scss';

const errorMessages = {
				submitFailed: 'Sorry, but your request could not be processed. Please try again.',
				mandatory: 'Please fill in this field',
				email: 'Please provide a valid email'
			};

let formCount = 0;

function trackFormSubmit() {
	event('form', 'submit', 'submitted');
}

function trackFormError(err) {
	event('form', 'error', err.toString());
}


function isEmpty(value) {
	return value == null || /^\s*$/.test(value);
}

function isEmail(value) {
	return /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(value);
}

function validateField(field) {
	const { validation, value } = field;

	let error;

	if (validation) {
		if (validation.mandatory) {
			error = isEmpty(value) ? errorMessages.mandatory : null;
		}

		if (validation.email) {
			error = !isEmail(value) ? errorMessages.email : null;
		}
	}

	return error;
}

class Form extends Component {
	static propTypes = {
		fields: PropTypes.arrayOf(PropTypes.shape({
			name: PropTypes.string.isRequired,
			value: PropTypes.string,
			validation: PropTypes.shape({
				mandatory: PropTypes.bool,
				email: PropTypes.bool
			}),
			error: PropTypes.string
		})).isRequired,
		submitLabel: PropTypes.string.isRequired,
		clearLabel: PropTypes.string,
		successMessage: PropTypes.string.isRequired,
		onSubmit: PropTypes.func.isRequired,
		onClear: PropTypes.func
	}

	static getDerivedStateFromProps(props, state) {
		return {
			...state,
			fields: props.fields.reduce((propFields, propField) => {
				const stateField = state.fields[propField.name];

				propFields[propField.name] = {
					...stateField,
					value: stateField ? stateField.value : null,
					...propField
				};

				return propFields;
			}, {})
		};
	}

	id = null

	state = {
		fields: {},
		hasError: false,
		status: null,
		processing: false
	}

	constructor(props) {
		super(props);

		this.id = formCount++;
		this.validate = this.validate.bind(this);
		this.reset = this.reset.bind(this);
		this.handleFieldChange = this.handleFieldChange.bind(this);
		this.handleClearButtonClick = this.handleClearButtonClick.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	validate() {
		const {
						state: {
							fields
						}
					} = this;

		let errors = 0;

		const _fields = Object.values(fields).reduce((fields, field) => {
				const error = validateField(field);

				if (error) {
					errors++;
				}

				fields[field.name] = {
					...field,
					error 
				};

				return fields;
			}, {});

		this.setState({
			fields: _fields
		});

		return errors === 0;
	}

	reset() {
		this.setState({
			status: null,
			hasError: false,
			processing: false,
			fields: Object.values(this.state.fields).reduce((fields, field) => {
				fields[field.name] = {
					...field,
					value: null,
					error: null
				};

				return fields;
			}, {})
		});
	}

	handleClearButtonClick() {
		this.reset();
		this.props.onClear();
	}

	async handleSubmit(e) {
		e.preventDefault();
		
		const { submitFailed } = errorMessages
				, {
						validate,
						reset,
						props: {
							successMessage,
							onSubmit
						},
						state: {
							fields,
							status
						}
					} = this;

		this.setState({ status: (<FontAwesomeIcon icon="spinner" spin />), processing: true });

		if (validate()) {
			const submitHandler = onSubmit(
							Object.values(fields)
										.filter(({ type }) => type !== FIELD_TYPES.BUTTON && type !== FIELD_TYPES.SUBMIT_BUTTON)
										.reduce((fields, { name, value }) => (fields[name] = value, fields), {})
						);

			if (submitHandler instanceof Promise) {
				try {
					await submitHandler;

					trackFormSubmit();
					reset();
					this.setState({ status: successMessage, hasError: false, processing: false });
				} catch (err) {
					console.log(err);
					trackFormError(err);
					this.setState({ status: submitFailed, hasError: true, processing: false });
				}
			} else if (typeof submitHandler === 'function' ) {
				try {
					submitHandler();
					
					trackFormSubmit();
					reset();
					this.setState({ status: successMessage, hasError: false, processing: false });
				} catch (err) {
					console.log(err);
					trackFormError(err);
					this.setState({ status: submitFailed, hasError: true, processing: false });
				}
			} else {
				trackFormSubmit();
				reset();
			}
		} else {
			event('form', 'submit', 'invalid');
			this.setState({ status, processing: false });
		}
	}

	handleFieldChange(name, value) {
		const {
						state,
						state: {
							fields
						}
					} = this;

		this.setState({
			...state,
			fields: {
				...fields,
				[name]: {
					...fields[name],
					value
				}
			}
		});
	}

	render() {
		const {
						id,
						handleFieldChange,
						handleSubmit,
						handleClearButtonClick,
						props: {
							submitLabel,
							clearLabel
						},
						state: {
							fields,
							hasError,
							status,
							processing
						}
					} = this;

		return (
			<form className={classnames('form', { 'form--error': hasError })} onSubmit={handleSubmit}>
				{Object.values(fields).map((field, index) => (
					<Field
						{...field}
						key={`form${id}-field${index}-${field.name}`}
						onChange={value => handleFieldChange(field.name, value)} />
				))}
				<div className="form__footer">
					<div className="form__footer__status">{status}</div>
					<div className="form__footer__buttons">
						{clearLabel &&
							<LinkButton disabled={processing} onClick={handleClearButtonClick}>{clearLabel}</LinkButton>
						}
						<Button disabled={processing} submit={true}>{submitLabel}</Button>
					</div>
				</div>
			</form>
		);
	}
}

export default Form;

export { FIELD_TYPES, FIELD_SIZES };

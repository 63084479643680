import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { event } from 'utils/analytics';

export const LINK_BUTTON_TYPE = 'LINK_BUTTON_TYPE';

const Button = ({ children, disabled, big, type, submit, analyticsRef, onClick }) => (
	<button
		disabled={disabled}
		className={classnames('button', { 'button--big': big, 'button--link': type })}
		type={submit ? "submit" : "button"}
		onClick={() => {
			analyticsRef && event('button', 'click', analyticsRef);
			onClick && onClick();
		}}>
		{children}
	</button>
);

Button.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.element,
		PropTypes.string
	]),
	disabled: PropTypes.bool,
	big: PropTypes.bool,
	type: PropTypes.oneOf([ LINK_BUTTON_TYPE ]),
	submit: PropTypes.bool,
	analyticsRef: PropTypes.string,
	onClick: PropTypes.func
};

export default Button;
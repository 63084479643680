import React from 'react';
import Container from 'components/container';
import FeatureItem from './FeatureItem';
import Symbol from './symbol.svg';
import features from './features.json';
import './Features.scss';

const Features = () => (
	<div className="features">
		<Container>
			<Container>
				<img className="features__symbol" src={Symbol} alt="symbol" />
				<h2 className="title">What Can We Do Together</h2>
				<div className="features__list">
					{features.map(({ title, content }, index) => (
						<FeatureItem key={`feature${index}`} title={title}>{content}</FeatureItem>
					))}
				</div>
			</Container>
		</Container>
	</div>
);

export default Features;

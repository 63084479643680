import React from 'react';
import PropTypes from 'prop-types';
import Container from 'components/container';
import FooterMenu from './FooterMenu';
import './Footer.scss';

const Footer = ({ onBlockchianClick }) => (
	<div className="footer">
		<Container>
			<FooterMenu onBlockchianClick={onBlockchianClick} />
		</Container>
	</div>
);

Footer.propTypes = {
	onBlockchianClick: PropTypes.func.isRequired
};

export default Footer;

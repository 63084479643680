import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { outboundLink } from 'utils/analytics';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const LinkedinLink = ({ className }) => (
	<a
		className={classnames(className, 'linkedin-link')}
		href="https://www.linkedin.com/in/barmatz"
		onClick={() => outboundLink('linkedin')}
		target="_blank"
		rel="noopener noreferrer">
		<FontAwesomeIcon icon={[ 'fab', 'linkedin' ]} />
	</a>
);

LinkedinLink.propTypes = {
	className: PropTypes.string
};

export default LinkedinLink;

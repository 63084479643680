import React from 'react';
import Container from 'components/container';
import { TwitterLink } from 'components/buttons';
import { yearsFromNow } from 'utils/dates';
import './Blockchain.scss';

const Blockchain = () => (
	<div className="blockchain">
		<div className="blockchain__featured-image">
			<Container>Take a giant leap into the future</Container>
		</div>
		<Container>
			<Container>
				<h2 className="title">Blockchain / Cryptocurrencies</h2>
				<p>In 2013 I co-founded Epiphyte, one of the first FinTech companies to offer business solutions for financial institutions utilising Distributed Ledger Technologies and token-based verifications. <strong>Thus began my journey in the emerging industry of blockchain.</strong></p>
				<p>You may have heard of the innovative technology if you are in the banking sector, interested in investment markets or perhaps you saw it mentioned on the news. Over the last {yearsFromNow('2009')} years, Blockchain has gained attention as the new technological leap, much like the Internet, that will change how our digital devices communicate and make decisions with each other.</p>
				<p>A blockchain is a collection of digital information on a sophisticated database, powered by cryptography and consensus mechanisms. The goal is to create records of data that can be shared but not changed. This is particularly useful for financial ecosystems and supply chains. But it could also change the way our personal data is owned by people and handled by institutions.</p>
				<p>Blockchain was first outlined in 1991 by Stuart Haber and W. Scott Stornetta as a fault-tolerant system for document management. In January 2009, following the financial crisis of ‘08, we witnessed the launch of Bitcoin by the pseudonymous creator Satoshi Nakamoto.</p>
				<q>A new electronic cash system that’s fully peer-to-peer, with no trusted third party.</q>
				<p>For the last {yearsFromNow('2013')} years, I have worked with large institutions and governments around the world to better understand the utilities of blockchain and the place of cryptocurrencies in our economy. I lead the construction of infrastructure underlying regulated investment products backed by cryptocurrencies and financial applications powered by Distributed Ledger Technologies. I also performed technical due diligence on blockchain companies and contributed to discussions that led to the implementation of new business and technological policies.</p>
				<p>Blockchain isn’t only about finance. Over 2.5 quintillion bytes of data are created every single day. Ensuring their authenticity and ownership is paramount for humanity as a technological society.</p>
				<br />
				<p>
					<TwitterLink /> Follow me on Twitter to learn more
				</p>
			</Container>
		</Container>
	</div>
);

export default Blockchain;

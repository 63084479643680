import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { close } from 'actions/modal';
import Container from 'components/container';
import { modalView } from 'utils/analytics';
import Form from './Form';
import './Modal.scss';

class Modal extends Component {
	static propTypes = {
		isOpen: PropTypes.bool,
		onRender: PropTypes.func,
		onOpen: PropTypes.func,
		onClose: PropTypes.func,
		onExit: PropTypes.func.isRequired
	}

	static defaultProps = {
		isOpen: false
	}

	element = null
	
	constructor(props) {
		super(props);

		this.handleComponentRendered = this.handleComponentRendered.bind(this);
	}

	componentDidMount() {
		this.handleComponentRendered();
	}

	componentDidUpdate() {
		const {
						handleComponentRendered,
						props: {
							isOpen,
							onOpen,
							onClose
						}
					} = this;

		handleComponentRendered();
		
		if (isOpen) {
			modalView('hire-me');
			onOpen && onOpen();
		} else {
			onClose && onClose();
		}
	}

	handleComponentRendered() {
		const {
						element: {
							scrollWidth,
							scrollHeight
						},
						props: {
							onRender
						}
					} = this;

		onRender && onRender(scrollWidth, scrollHeight);
	}

	render() {
		const {
						props: {
							isOpen,
							onExit
						}
					} = this;

		return (
			<div className={classnames('modal', { 'modal--open': isOpen })} ref={element => this.element = element}>
				<div className="modal__backdrop" onClick={onExit}></div>
				<Container>
					<div className="modal__close-button" onClick={onExit}>
						<FontAwesomeIcon icon={[ 'fas', 'times' ]} />
					</div>
					<h2>Hire Me</h2>
					<p>
						Need help with a project or want advise to improve your company?<br />
						Send me a message below or contact me directly at <a href="mailto:o@barmatz.co">o@barmatz.co</a>.<br /><br />
					</p>
					<Form />
				</Container>
			</div>
		);
	}
}

export default connect(
	({ modal: { isOpen }}) => ({
			isOpen	
	}),
	dispatch => ({
		onExit: () => dispatch(close())	
	})
)(Modal);
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './Field.scss';

export const FIELD_TYPES = {
							INPUT: 'INPUT',
							TEXTAREA: 'TEXTAREA'
						}
					, FIELD_SIZES = {
						SHORT: 'SHORT'
					};

function getElementTagByType(type) {
	switch (type) {
		case FIELD_TYPES.TEXTAREA:
			return 'textarea';
		case FIELD_TYPES.INPUT:
		default:
			return 'input';
	}
}

class Field extends Component {
	static propTypes = {
		type: PropTypes.oneOf(Object.values(FIELD_TYPES)),
		name: PropTypes.string.isRequired,
		placeholder: PropTypes.string,
		value: PropTypes.string,
		size: PropTypes.oneOf(Object.values(FIELD_SIZES)),
		error: PropTypes.string,
		onChange: PropTypes.func.isRequired
	}

	static defaultProps = {
		type: FIELD_TYPES.INPUT
	}

	static getDerivedStateFromProps({ type }, state) {
		return {
			...state,
			ElementTag: getElementTagByType(type)
		};
	}

	state = {
		ElementTag: null
	}

	constructor(props) {
		super(props);

		this.handleChange = this.handleChange.bind(this);
	}

	handleChange({target: { value }}) {
		this.props.onChange(value);
	}

	render() {
		const {
						handleChange,
						props: {
							name,
							placeholder,
							value,
							size,
							error
						},
						state: {
							ElementTag
						}
					} = this;

		return (
			<div className={classnames('form__field', { 'form__field--short': size === FIELD_SIZES.SHORT, 'form__field--error': error })}>
				<ElementTag
					name={name}
					placeholder={placeholder}
					value={value === null ? '' : value}
					onChange={handleChange} />
					<div className="form__field__error-message">{error}</div>
			</div>
		);
	}
}

export default Field;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { event } from 'utils/analytics';
import './Tabs.scss';

let tabCount = 0;

class Tabs extends Component {
	static propTypes = {
		items: PropTypes.arrayOf(PropTypes.shape({
			label: PropTypes.string.isRequired,
			content: PropTypes.any.isRequired
		})).isRequired,
		analyticsRef: PropTypes.string
	}

	static getDerivedStateFromProps({ items }, state) {
		return { ...state, currentContent: state.currentContent || (items[0] && items[0].content), activeItemIndex: state.activeItemIndex || 0 };
	}

	state = {
		currentContent: null,
		activeItemIndex: 0
	}

	constructor(props) {
		super(props);

		this.handleItemClick = this.handleItemClick.bind(this);
	}

	handleItemClick(label, content, index) {
		const { analyticsRef } = this.props;

		analyticsRef && event(analyticsRef, 'click', label);
		
		this.setState({
			currentContent: content,
			activeItemIndex: index
		});
	}

	render() {
		const {
						handleItemClick,
						props: {
							items
						},
						state: {
							currentContent,
							activeItemIndex
						}
					} = this;

		return (
			<div className="tabs">
				<div className="tabs__header">
					{items.map(({ label, content }, index) => (
						<button
							key={`tab${tabCount++}`}
							className={classnames('tabs__header__button', { 'tabs__header__button--active': index === activeItemIndex })}
							onClick={() => handleItemClick(label, content, index)}>
							{label}
						</button>
					))}
				</div>
				<div className="tabs__content">{currentContent}</div>
			</div>
		)
	}
}

export default Tabs;

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tabs from 'components/tabs';
import './CVSkills.scss';

const CVSkills = ({ className, skills }) => (
	<div className={classnames(className, 'cv-skills')}>
		<h2>Skills</h2>
		<Tabs analyticsRef="CV Skills" items={Object.keys(skills).map((label, categoryIndex) => ({
			label: `${label} (${skills[label].length})`,
			content: skills[label].map((skill, index) => (
				<div key={`cv-skill${(categoryIndex + 1) * index}`} className="cv-skills__skill">
					<FontAwesomeIcon className="cv-skills__skill__icon" icon={[ 'fas', 'circle' ]} />
					<span className="cv-skills__skill__label">{skill}</span>
				</div>
			))
		}))} />
	</div>
);

CVSkills.propTypes = {
	className: PropTypes.string,
	skills: PropTypes.object
};

export default CVSkills;

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './Container.scss';

const Container = ({ children, row }) => (
	<div className={classnames('container', { row })}>{children}</div>
);

Container.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.element,
		PropTypes.arrayOf(PropTypes.element),
		PropTypes.string
	]),
	row: PropTypes.bool
};

export default Container;
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { yearsFromNow } from 'utils/dates';
import './CVProfile.scss';

const CVProfile = ({ className }) => (
	<div className={classnames(className, 'cv-profile')}>
		<h2>Profile</h2>
		<div className="cv-profile__content">
			<p>
				Self-driven technology advocate and veteran manager with {yearsFromNow('2005')} years working in advertising, blockchain, financial industries, media, and e-commerce worldwide. Since 2013, acting as a co-founder or executive to top blockchain companies, creating business strategies, leading product development, pitching to investors, and advising on sales and marketing.
				<br /><br />
				I have collaborated with institutions, governments, and regulators to spearhead new technologies, initiatives and policies including deal negotiation and supervised delivery for top financial institutions such as Coinbase, Barclays, Santander, CBA, and Visa.
			</p>
			<p>
				<strong>My passion for state-of-the-art technology and innovation has led me to award-winning companies challenging their field.</strong> I look to do good with the use of technology and have spent recent years promoting distributed ledger technologies (DLT) and superb attention to user experience. Today, alongside technology and product development, I share my knowledge with early-stage startups to help them create and achieve coherent narratives, build and manage development teams with solid delivery plans, secure investments and acquire customers.
			</p>
		</div>
	</div>
);

CVProfile.propTypes = {
	className: PropTypes.string
};

export default CVProfile;

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './CVExperienceEntry.scss';

let achievementCounter = 0;

function dateConverter(date) {
	if (date) {
		return moment(date).format('MMM YYYY');
	}

	return date;
}

function renderAchievement(companyName, jobTitle, achievement) {
	if (achievement instanceof Array) {
		return (
			<ul key={`${companyName}-${jobTitle}-subachievement${achievementCounter}`} className="cv-experience__entry__achievements--sub">{achievement.map(subAchievement => renderAchievement(companyName, jobTitle, subAchievement))}</ul>
		);
	}

	return (
		<li key={`${companyName}-${jobTitle}-achievement${achievementCounter++}`} className="cv-exeprience__achipeements__achievement">{achievement}</li>
	);
}

const CVExperienceEntry = ({ companyName, jobTitle, description, startDate, endDate, achievements }) => (
	<div className="cv-experience__entry">
		<h3 className="cv-experience__entry__title">
			<div className="cv-experience__entry__company-name">{companyName}</div>
			<div className="cv-experience__entry__job-title">/ {jobTitle}</div>
			<p className="cv-experience__entry__dates">
				<FontAwesomeIcon className="cv-experience__entry__dates__icon" icon={[ 'far', 'calendar-alt' ]} />
				{dateConverter(startDate)} - {dateConverter(endDate) || 'Present'}
			</p>
		</h3>
		<p className="cv-experience__entry__description">{description}</p>
		{achievements &&
			<ul className="cv-experience__entry__achievements">
				{achievements.map(achievement => renderAchievement(companyName, jobTitle, achievement))}
			</ul>
		}
	</div>
);

CVExperienceEntry.propTypes = {
	companyName: PropTypes.string.isRequired,
	jobTitle: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	startDate: PropTypes.instanceOf(Date).isRequired,
	endDate: PropTypes.instanceOf(Date),
	achievements: PropTypes.arrayOf(PropTypes.oneOfType(PropTypes.string, PropTypes.arrayOf(PropTypes.string)))
};

export default CVExperienceEntry;
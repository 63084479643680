import React, { Component } from 'react';
import AbstractForm, { FIELD_TYPES, FIELD_SIZES } from 'components/forms/Form';
import { sendMessage } from 'api';

class Form extends Component {
	state = {
		fields: [{
			type: FIELD_TYPES.INPUT,
			name: 'name',
			placeholder: 'Your name',
			size: FIELD_SIZES.SHORT,
			validation: {
				mandatory: true
			}
		}, {
			type: FIELD_TYPES.INPUT,
			name: 'email',
			placeholder: 'Contact email',
			size: FIELD_SIZES.SHORT,
			validation: {
				mandatory: true,
				email: true
			}
		}, {
			type: FIELD_TYPES.TEXTAREA,
			name: 'message',
			placeholder: 'What would you like to talk about?',
			validation: {
				mandatory: true
			}
		}]
	}

	constructor(props) {
		super(props);

		this.handleInvalid = this.handleInvalid.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleInvalid() {
	}

	handleSubmit({ name, email, message }) {
		return sendMessage(name, email, message, 'New Message from barmatz.co');
	}

	render() {
		const {
						handleInvalid,
						handleSubmit,
						state: {
							fields
						}
					} = this;

		return (
			<AbstractForm
				fields={fields}
				submitLabel="Send message"
				successMessage="Thank you for contacting me. I will be in touch shortly."
				onSubmit={handleSubmit}
				onInvalid={handleInvalid} />
		);
	}
}

export default Form;

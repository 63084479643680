import ReactGA from 'react-ga';

ReactGA.initialize('UA-163266845-1', process.env.NODE_ENV === 'development' ? { debug: true, test: true } : null);
ReactGA.pageview(window.location.pathname + window.location.search);

export const pageView = path => ReactGA.pageview(path);

export const modalView = name => ReactGA.modalview(name);

export const event = (category, action, label, value=null, nonIteraction=false, transport=null) => ReactGA.event({ category, action, value, label, nonIteraction, transport });

export const timing = (category, variable, value, label=null) => ReactGA.timing({ category, variable, value, label });

export const outboundLink = (label, hitCallback=null, trackerName=null) => ReactGA.outboundLink({ label }, hitCallback || (() => null), trackerName);

export const exception = (description=null, fatal=false) => ReactGA.exception({ description, fatal });

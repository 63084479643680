import React from 'react';
import PropTypes from 'prop-types';
import CommonMenu from 'components/menus/CommonMenu';
import './HeaderMenu.scss';

const HeaderMenu = ({ onBlockchianClick }) => (
	<div className="header__menu">
		<CommonMenu onBlockchianClick={onBlockchianClick} />
	</div>
);

HeaderMenu.propTypes = {
	onBlockchianClick: PropTypes.func.isRequired
};

export default HeaderMenu;

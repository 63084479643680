import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Container from 'components/container';
import MenuItem from 'components/menus/MenuItem';
import { event } from 'utils/analytics';
import CVAchievements from './CVAchievements';
import CVProfile from './CVProfile';
import CVSkills from './CVSkills';
import CVExperience from './CVExperience';
import { skills, achievements, experience } from './cv.json';
import './CV.scss';

function isSectionWithinView(section) {
	const { y, height } = section.getBoundingClientRect()
			, { innerHeight } = window
			, topBound = innerHeight * .1
			, bottomBound = innerHeight * .5;

	return (y < bottomBound) && ((y + height) > topBound);
}

class CV extends Component {
	static propTypes = {
		isModalOpen: PropTypes.bool
	}

	headerElement = null

	sectionContainerElement = null
	
	profileElement = null
	
	skillsElement = null

	achievementsElement = null

	workExperienceElement = null

	state = {
		isHeaderSticky: false,
		currentSection: null
	}

	constructor(props) {
		super(props);

		this.handleScroll = this.handleScroll.bind(this);
		this.handleDownloadPDFClick = this.handleDownloadPDFClick.bind(this);
	}

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll, { passive: true });
		this.handleScroll();
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
	}

	handleScroll() {
		const {
						headerElement,
						sectionContainerElement,
						profileElement,
						skillsElement,
						achievementsElement,
						workExperienceElement,
						props: {
							isModalOpen
						},
						state
					} = this
				, { scrollY } = window
				, headerStickyBreakpoint = headerElement.offsetHeight
				, isHeaderSticky = isModalOpen || scrollY > headerStickyBreakpoint;

		let currentSection;

		if (sectionContainerElement.getBoundingClientRect().y > 0) {
			currentSection = null;
		} else if (isSectionWithinView(profileElement)) {
			currentSection = 'Profile';
		} else if (isSectionWithinView(skillsElement)) {
			currentSection = 'Skills';
		} else if (isSectionWithinView(achievementsElement)) {
			currentSection = 'Achievements';
		} else if (isSectionWithinView(workExperienceElement)) {
			currentSection = 'Work Experience';
		}

		if (state.currentSection !== currentSection) {
			event('CV', 'viewing', currentSection || 'top');
		}

		this.setState({ isHeaderSticky, currentSection });
	}

	handleDownloadPDFClick() {
		window.open('./or-daniel-barmatz-cv.pdf');
	}

	render() {
		const {
							handleDownloadPDFClick,
							state: {
								isHeaderSticky,
								currentSection
							}
					} = this;

		return (
			<div className="cv">
				<Container>
					<Container>
					<header ref={element => this.headerElement = element} className={classnames('cv__header', { 'cv__header--sticky': isHeaderSticky })}>
						<div className="cv__header__menu">
							<MenuItem label="Back" icon="far arrow-alt-circle-left" link="/" />
							<MenuItem label="Download PDF" onClick={handleDownloadPDFClick} />
						</div>
						<div className="cv__header__section-indicator">
							{currentSection}
						</div>
					</header>
					<h1 className="cv__title">
						Or Daniel Barmatz / <strong>Curriculum Vitae</strong>
					</h1>
					<div ref={element => this.sectionContainerElement = element}>
						<div ref={element => this.profileElement = element}>
							<CVProfile className="cv__section" />
						</div>
						<div ref={element => this.skillsElement = element}>
							<CVSkills className="cv__section" skills={skills} />
						</div>
						<div ref={element => this.achievementsElement = element}>
							<CVAchievements className="cv__section" achievements={achievements} />
						</div>
						<div ref={element => this.workExperienceElement = element}>
							<CVExperience className="cv__section" experience={experience} />
						</div>
					</div>
					</Container>
				</Container>
			</div>
		);
	}
}

export default connect(
	({ modal: { isOpen }}) => ({
		isModalOpen: isOpen
	})
)(CV);

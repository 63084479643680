import { OPEN, CLOSE } from 'actions/modal';

const initialState = {
				scrollYPosition: 0
		};

let scrollY = 0;

function getScrollY() {
	return scrollY = window.scrollY;
}

export default (state = initialState, action) => {
	switch (action.type) {
		case OPEN:
			return { ...state, scrollYPosition: getScrollY() };
		case CLOSE:
			return { ...state, scrollYPosition: scrollY };
		default:
			return state;
	}
}

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { outboundLink } from 'utils/analytics';
import './TwitterLink.scss';

const TwitterLink = ({ className, withHandle }) => (
	<a
		className={classnames(className, 'twitter-link')}
		href="https://twitter.com/barmatz"
		onClick={() => outboundLink('twitter')}
		target="_blank"
		rel="noopener noreferrer">
		<FontAwesomeIcon icon={[ 'fab', 'twitter' ]} />
		{withHandle && '@barmatz'}
	</a>
);

TwitterLink.propTypes = {
	className: PropTypes.string,
	withHandle: PropTypes.bool
};

TwitterLink.defaultProps = {
	withHandle: true
};

export default TwitterLink;

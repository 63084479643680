import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import CVExperienceEntry from './CVExperienceEntry';

const CVExperience = ({ className, experience }) => (
	<div className={classnames(className, 'cv-experience')}>
		<h2>Professional Experience</h2>
		<div>
			{experience.map(({ companyName, jobTitle, startDate, endDate, description, achievements }, index) => (
				<CVExperienceEntry
					key={`cv-entry${index}`}
					companyName={companyName}
					jobTitle={jobTitle}
					startDate={startDate && new Date(startDate)}
					endDate={endDate && new Date(endDate)}
					description={description}
					achievements={achievements}
					/>
			))}
		</div>
	</div>
);

CVExperience.propTypes = {
	className: PropTypes.string,
	experience: PropTypes.arrayOf(PropTypes.shape({
		companyName: PropTypes.string.isRequired,
		jobTitle: PropTypes.string.isRequired,
		startDate: PropTypes.string.isRequired,
		endDate: PropTypes.string,
		description: PropTypes.string.isRequired,
		achievements: PropTypes.arrayOf(PropTypes.oneOfType(PropTypes.string, PropTypes.arrayOf(PropTypes.string))).isRequired
	}))
};

export default CVExperience;

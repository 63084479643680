import React, { Component } from 'react';
import { About, Features, Blockchain } from 'pages';
import Header from './header';
import Footer from './footer';
import './Home.scss';

function scrollToElement(element) {	
	window.scrollTo(0, window.scrollY + element.getBoundingClientRect().y);
}

class Home extends Component {
	blockchainElement = null

	constructor(props) {
		super(props);

		this.handleBlockchainClick = this.handleBlockchainClick.bind(this);
	}

	handleBlockchainClick() {
		scrollToElement(this.blockchainElement);
	}

	render() {
		const {
						handleBlockchainClick
					} = this;

		return (
			<div className="home">
				<Header onBlockchianClick={handleBlockchainClick} />
				<About />
				<Features />
				<div ref={element => this.blockchainElement = element}>
					<Blockchain />
				</div>
				<Footer onBlockchianClick={handleBlockchainClick} />
			</div>
		);
	}
}

export default Home;
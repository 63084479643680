import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from './MenuItem';

const CommonMenu = ({ onBlockchianClick }) => (
	<span>
		<MenuItem label="Blockchain" onClick={onBlockchianClick} />	
		<MenuItem label="CV" link="cv" />	
	</span>
);

CommonMenu.propTypes = {
	onBlockchianClick: PropTypes.func.isRequired
};

export default CommonMenu;
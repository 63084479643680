import React from 'react';
import PropTypes from 'prop-types';
import CommonMenu from 'components/menus/CommonMenu';
import MenuItem from 'components/menus/MenuItem';
import './FooterMenu.scss';

const { TWITTER_TYPE, LINKEDIN_TYPE } = MenuItem;

const FooterMenu = ({ onBlockchianClick }) => (
	<div className="footer__menu">
		<CommonMenu onBlockchianClick={onBlockchianClick} />
		<MenuItem type={TWITTER_TYPE} />	
		<MenuItem type={LINKEDIN_TYPE} />	
	</div>
);

FooterMenu.propTypes = {
	onBlockchianClick: PropTypes.func.isRequired
};

export default FooterMenu;

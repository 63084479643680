import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { pageView } from 'utils/analytics';

class RouterWatcher extends Component {
	static propTypes = {
		location: PropTypes.shape({
			location: PropTypes.string,
			pathname: PropTypes.string
		})
	}

	componentDidUpdate({ location }) {
		if (this.props.location !== location) {
			pageView(this.props.location.pathname);
      window.scrollTo(0, 0);
    }
	}

	render() {
		return '';
	}
}

export default withRouter(RouterWatcher);

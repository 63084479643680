import { OPEN, CLOSE } from 'actions/modal';

const initialState = {
				isOpen: false
		};

export default (state = initialState, action) => {
	switch (action.type) {
		case OPEN:
			return { ...state, isOpen: true };
		case CLOSE:
			return { ...state, isOpen: false };
		default:
			return state;
	}
}

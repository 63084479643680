import React from 'react';
import PropTypes from 'prop-types';
import './FeatureItem.scss';

const FeatureItem = ({ children, title }) => (
	<div className="features__list__item">
		<h3 className="features__list__item__title">{title}</h3>
		{children}
	</div>
);

FeatureItem.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.element,
		PropTypes.arrayOf(PropTypes.element),
		PropTypes.string
	]),
	title: PropTypes.string.isRequired
};

export default FeatureItem;

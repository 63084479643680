import React from 'react';
import PropTypes from 'prop-types';
import Container from 'components/container';
import HeaderMenu from './HeaderMenu';
import HeaderSlogan from './HeaderSlogan';
import Or from './or-pic.png';
import './Header.scss';

const Header = ({ onBlockchianClick }) => (
	<header className="header">
		<Container row={true}>
			<HeaderMenu onBlockchianClick={onBlockchianClick} />
			<HeaderSlogan />
			<img className="header__pic" src={Or} alt="Or Barmatz" />
		</Container>
	</header>
);

Header.propTypes = {
	onBlockchianClick: PropTypes.func.isRequired
};

export default Header;

import React from 'react';
import './HeaderSlogan.scss';

const HeaderSlogan = () => (
	<h1 className="header__slogan">
		Building Smart Businesses<br />
		with <span className="header__slogan__highlight">Creative Technology</span>
	</h1>
);

export default HeaderSlogan;
